import '../assets/css/nds.css';

import React, { Component } from "react"
import SignatureCanvas from 'react-signature-canvas'
import Formsy from 'formsy-react';
import { connect } from "react-redux"
import { navigate } from "gatsby"

import Slider from 'rc-slider';
import '../assets/css/slider.css';

import Layout from "../components/layout"
import Steps from "../components/ndstep"
import bottomLine from "../images/line.png"
import SEO from "../components/seo"
import * as actionCreators from './../store/actions';
import MyInput from "../components/MyInput"
import MyCheckbox from "../components/MyCheckbox";
import MyInputMask from "../components/MyInputMask";
import ButtonLoader from "../components/buttonLoader";
import infosvg from "../images/info.svg"


class sign extends Component {
  constructor(props) {
    super(props);
    this.state = {
      canSubmit: false,
      info:null,
      msg:null
    };
   
  }
  sigPad = {}
  
  componentDidMount = async () => {
    let uri = process.env.AS_API_URL+'info';
    console.log(uri)
    await fetch(uri,{
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
            uid: global.dealertoken,
        })
      })
      .then(res => res.json())
      .then((res) => {
        console.log(res,res.status)
          if(res.status && res.data.status == 0){
            let usr = {};
            let deal = res.data;
            let ext = 1;
            for (let u of deal.users) {
                if(u.type == "Owner") usr.owner = u;
                else if(u.type == "Agent") usr.agent = u;
                /* else if(u.type == "Admin") usr.admin = u;
                else if(u.type == "Account") usr.acc = u;
                else if(u.type == "Additional"){
                    usr['usr'+ext] = u;
                    ext++;
                }   */
            }            
            
            let dms = '';
            let other = '';
            if(deal.dms=='ADP') dms = 'ADP';
            else if(deal.dms=='R&R') dms = 'R&R';
            else if(deal.dms=='DealerTrack') dms = 'DealerTrack';
            else if(deal.dms=='Advent') dms = 'Advent';
            else if(deal.dms=='AutoMate') dms = 'AutoMate';
            else if(deal.dms!=''){
              dms = 'Other';
              other = deal.dms
            } 

            let info = {
                "deal_type":deal.deal_type,
                "deal_id":global.dealertoken,
                "producer": {
                "name": this.toTitle(deal.ds_name),
                "street": deal.street,
                "city": deal.city,
                "state": deal.state,
                "zip": deal.zip,

                "phone": usr.owner.phone,
                "bo_name": this.toTitle(usr.owner.name),
                "email": usr.owner.email,
                "title": this.toTitle(usr.owner.title),

                "sales_email": usr.agent.email,
                "sales_name": this.toTitle(usr.agent.name),
                "sales_phone": usr.agent.phone,
                "taxId": deal.taxId,
                "website": deal.website,
                "isAgent": false,
                "sc": false                
                },
                "contact": {
                  "sysAdminName": usr.admin?usr.admin.name:'',
                  "sysAdminEmail":  usr.admin?usr.admin.email:'',
                  "sysAdminPhone":  usr.admin?usr.admin.phone:'',
                  "accName":  usr.admin?usr.admin.name:'',
                  "accEmail":  usr.admin?usr.admin.email:'',
                  "accPhone":  usr.admin?usr.admin.phone:'',
                  "sysUsrName1": usr.usr1?usr.usr1.name:'',
                  "sysUsrEmail1": usr.usr1?usr.usr1.email:'',
                  "sysUsrPhone1": usr.usr1?usr.usr1.phone:'',
                  "access1": usr.usr1?usr.usr1.title:'',

                  "sysUsrName2": usr.usr2?usr.usr2.name:'',
                  "sysUsrEmail2": usr.usr2?usr.usr2.email:'',
                  "sysUsrPhone2": usr.usr2?usr.usr2.phone:'',
                  "access2": usr.usr2?usr.usr2.title:'',

                  "sysUsrName3": usr.usr3?usr.usr3.name:'',
                  "sysUsrEmail3": usr.usr3?usr.usr3.email:'',
                  "sysUsrPhone3": usr.usr3?usr.usr3.phone:'',
                  "access3": usr.usr3?usr.usr3.title:''
                },
                "products": {
                  "dealStructure": deal.structure,
                  "remitType": deal.remit,
                  "ProductActivate": deal.products,
                  "incentive":deal.incentive
                },
                "dmsType": {
                  "DMS": {
                      "ADP": dms=='ADP'?true:false,
                      "RR": dms=='R&R'?true:false,
                      "DealerTrack": dms=='DealerTrack'?true:false,
                      "Advent": dms=='Advent'?true:false,
                      "AutoMate": dms=='AutoMate'?true:false,
                      "Other": other
                  },
                  ADP:{
                      accountFI:dms=='ADP'?deal.fi:null,
                      CMF:dms=='ADP'?deal.cmf:null,
                  },
                  RR:{
                    Store:dms=='R&R'?deal.store:null,
                    FI:dms=='R&R'?deal.fi:null,
                    userId:dms=='R&R'?deal.cmf:null,
                  },
                  Other:other,
                  name:dms
                },
                "payee": {}
            }
            console.log(info)
            this.setState({info:info});
          } else {
            this.setState({msg:'Sorry, Token is expired'});
          }
      });
    
  }

  
  clear = () => {
    this.sigPad.clear();
    this.setState({ canSubmit: false });
    //this.checkMealso();
  }
  

  checkMealso = () => {
    let drw = this.sigPad.getTrimmedCanvas().toDataURL('image/png');
    if(drw.length>5000){
      this.setState({ canSubmit: true });
    }
  }

  toTitle = (str) => {
    if(str){
      return str.replace(
          /\w\S*/g,
          function(txt) {
              return txt.charAt(0).toUpperCase() + txt.substr(1);
          }
      );
      }  else {
        return str;
      }
  }

  
  submit = async(model) => {
    const { info } = this.state;
        const { showLoader, hideLoader} = this.props;
        showLoader();
        const data = new FormData();
        data.append('sign',this.sigPad.getTrimmedCanvas().toDataURL('image/png'))
        data.append('issc',0)
        data.append('b2b',info.deal_type)
        data.append('agrinfo',JSON.stringify(info))
        
      await fetch(process.env.BEDFORD_API_URL,{method: "POST", body: data})
      .then(res => res.json())
      .then((res) => {
        sessionStorage.clear();
        hideLoader();
        navigate("/paok/?type="+info.deal_type);
        
      });
    
  }

  onChangeHandler=event=>{
    this.setState({ selectedFile: event.target.files[0]})
    this.state.selectedFile = event.target.files[0];
    //this.checkMealso();
  }
   
  render() {
    const { canSubmit, info, infoError,msg } = this.state;
    const { buttonLoader} = this.props;

    
   
    return (
      <Layout layoutType="nds">
      
        <section className={(info && info.deal_type=='Keyfetch')?"ndsec notop kfout":"ndsec notop"}>
          <div className="custom-container vehinfo">
            {/* <div className="">
              <div className="title-text">
                <h2>{'Sign'}</h2>
              </div>
            </div> */}
            
            <div className="body-content">
            {info?
            <Formsy ref="nds5Form" onValidSubmit={this.submit} onValid={this.enableButton} onInvalid={this.disableButton}>
                <div className="form-container-inline">
                    <div className="form-left">
                    
                    <div className='tab-content'>
                        <small>
                          <br></br>
                            <b>{info.producer.name}</b><br></br>
                            {info.producer.street+', '+ info.producer.city+', '+info.producer.state+', '+info.producer.zip}
                            <br></br>{'TaxId : '+info.producer.taxId}<br></br>
                            {'Website : '+info.producer.website}<br></br>

                            <br></br>
                            <b>Business Owner</b><br></br>
                            {info.producer.bo_name+'('+info.producer.title +')'}<br></br>
                            {'Email : '+info.producer.email}<br></br>
                            {'Phone : '+info.producer.phone}<br></br>
                            
                            <br></br><b>Products</b><br></br>
                            {(info.products.dealStructure)?
                            <>Deal Structure : {info.products.dealStructure}<br></br></>
                            :''}
                            {'Products : '+info.products.ProductActivate}<br></br>
                            {/* {'DMS : '+info.dmsType.name}
                            {info.dmsType.Other?' ('+info.dmsType.Other+')':''}
                            <br></br> */}
                            {(info.products.incentive>0)?
                            <>Incentive : {info.products.incentive}<br></br></>
                            :''}
                            

                            <br></br>
                            <b>Rep</b><br></br>
                            {info.producer.sales_name}<br></br>
                            {'Email : '+info.producer.sales_email}<br></br>
                            {'Phone : '+info.producer.sales_phone}
                        </small>
                    </div>
                    
                    </div>
                    <div className="form-right">
                    <div className="form-label-group error">
                        <label>Please read the following and sign below *</label>
                        <small>
                        By your signature below, you are agreeing to be bound by the terms and conditions of the Producer Agreement, a sample of which can be accessed&nbsp; 
                        {info.deal_type=='DOWC' && 
                        <a className="here" href="https://dowc-asserts.s3.amazonaws.com/ProducerAgreement20.pdf" target="_blank">here</a>
                        }
                        {info.deal_type=='SC' && 
                        <a className="here" href="https://dowc-asserts.s3.amazonaws.com/SCPA-Sample.pdf" target="_blank">here</a>
                        }
                        {info.deal_type=='Keyfetch' && 
                        <a className="here" href="https://dowc-asserts.s3.amazonaws.com/Keyfetch-Producer-Agreement-Sample.pdf" target="_blank">here</a>
                        }
                        &nbsp;prior to your signature. You represent that the information you have provided is true and correct and you understand that your signature will be applied to the Producer Agreement upon selecting “submit” below. Any further information needed to complete exhibits to the Producer Agreement will continue to be gathered during the remainder of the onboarding process and forwarded to you for signature.
                        
                        </small>
                        
                        <SignatureCanvas canvasProps={{className: 'bosign'}} 
                        throttle={0} dotSize={2} minWidth={0.1} maxWidth={2} minDistance={1}
                        ref={(ref) => { this.sigPad = ref }}
                        onEnd={(e) => this.checkMealso()}
                        />
                        <a className="clearbtn" onClick={this.clear}>Clear</a>
                        
                        
                    </div>

                    <div className="error-txt">{infoError}</div>
                    <div className="btn-container">
                        <button type="submit" disabled={!canSubmit || buttonLoader} className={!canSubmit || buttonLoader ? 'btn disabled wizard-btn creat-btn' : 'btn wizard-btn creat-btn'}>
                            <ButtonLoader isLoading={buttonLoader} label={'Submit'} />
                        </button>
                    </div>  
                        
                    </div>
                    
                </div>
                <div className="txtright">* Field is required</div>
    
                
            </Formsy>
            :''}
            <br></br>
            <center>{msg?msg:null}</center>
              
            </div>
          </div>
          <div className="vehicle-bottom-line bottom-line">
            <img src={bottomLine} alt="line" />
          </div>
        </section>
      </Layout>
    );
  }
}

const mapStateToProps = state => {
  return {
    pageLoader: state.primary.pageLoader,
    buttonLoader: state.primary.buttonLoader
  }
};

const mapDispatchToProps = dispatch => {
  return {
    showLoader: () => dispatch(actionCreators.showLoader()),
    hideLoader: () => dispatch(actionCreators.hideLoader()),
    
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(sign);